import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { graphql, Link, navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/layout/layout';

import { Helmet } from 'react-helmet';

const Members = ({ data }) => {
    const { allContentfulLatestInfo: { nodes: items } } = data;
    const { indexImage: { childImageSharp: image } } = data;
    const itemsOrdered = items
                        .map(item => {
                            return {
                                id: item.id,
                                infoDate: item.infoDate,
                                infoDateFlags: item.infoDate.split(','),
                                compareDateString: parseInt(item.infoDate.replace(/[ ,.]/g, "")),
                                outputInfoDate: item.infoDate.replace(/,/g, '/'),
                                infoItems: item.infoItems
                            }
                        })
                        .sort((a, b) => a.compareDateString - b.compareDateString)
                        .reverse();
    const date = new Date();

    const { register, handleSubmit, errors, getValues } = useForm();

    const [ _authUrl, _setAuthUrl ] = useState("");

    const onSubmit = (data) => {
        const http = new XMLHttpRequest();
        if (typeof http !== "undefined") {
            http.open("get", _authUrl, false, getValues("username"), getValues("password"));
            http.send("");
            if (http.status === 200) {
                navigate('/members-data/');
            }
            else {
                alert("ユーザー名又は、パスワードが正しくありません。");
            }
        }
        
    };

    useEffect(() => {
        _setAuthUrl(window?.location.origin + '/members-data');
    }, []);

    return (
        <Layout location="members" metadata={ { title: "会員専用｜株式会社テストサービス" } }>
            <main className="page--members page u-margin-bottom-medium">
                <noscript>JavaScript を適切に有効にする必要があります</noscript>
                <section className="login-card u-margin-top-medium u-margin-bottom-medium">
                    <div className="login-card__flex-item login-card__flex-item--left">
                        <BackgroundImage className="login-card__background" fluid={ image.fluid }>
                            <div className="login-card__background-overlay"></div>
                            <div class="login-card__text-container">
                                <h3 className="login-card__header u-margin-bottom-small">
                                    会員専用ページにようこそ、この情報へのアクセスはメンバーに限定されてます。
                                </h3>
                                <p className="login-card__subhead small-text">
                                    ※会員の方でIDとパスワードが不明な方はこちらまでお問い合わせください。
                                </p>
                            </div>
                            
                        </BackgroundImage>
                    </div>
                    <div className="login-card__flex-item login-card__flex-item--right">
                        <form className="login-card__form" onSubmit={ handleSubmit(onSubmit) }>
                            <h3 className="heading-tertiary u-margin-bottom-small">ログインしてください。</h3>
                            <div class="login-card__form-group">
                                <input 
                                    class="login-card__form-input" 
                                    type="text" 
                                    placeholder="ユーザー名" 
                                    id="username"
                                    name="username"
                                    ref={ register({ required: true }) }  
                                    required />
                                <label class="login-card__form-label" for="username">ユーザー名</label>
                            </div>
                            <div class="login-card__form-group">
                                <input 
                                    class="login-card__form-input" 
                                    type="password" 
                                    placeholder="パスワード" 
                                    id="password"
                                    name="password"
                                    ref={ register({ required: true }) }  
                                    required />
                                <label class="login-card__form-label" for="password">パスワード</label>
                            </div>
                            <div class="login-card__form-group login-card__form-group--submit-area">
                                <input className="button button--primary login-card__submit-button" type="submit" value="送信する" />
                                <Link to="/contact/">パスワードをお忘れの方 &nbsp; &gt;</Link>
                            </div>
                        </form>
                    </div>
                </section>
                <h3 className="heading-tertiary u-margin-bottom-small">最新情報</h3>
                <section className="collection">
                    { itemsOrdered.map(item => {
                        return (
                        <div className=
                                { item.infoDateFlags[0] === date.getFullYear().toString().substr(2, 2) ? 
                                    'collection__item collection__item--current-year' :
                                    'collection__item'
                                } 
                            key={ item.id }>
                            <div className="collection__flex-item--date">{ item.outputInfoDate }</div>
                            <div className="collection__flex-item--item">
                                <ul className="collection__list">
                                    { 
                                        item.infoItems?.map((infoItem, j) => {
                                            return <li className="collection__list-item" key={ j }>{ infoItem }</li>
                                        })
                                    }
                                </ul>    
                            </div>
                        </div>
                        )
                    }) }
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
{
    allContentfulLatestInfo {
        nodes {
            id
            infoDate(formatString: "YY, MM, DD")
            infoItems
        }
    }
    indexImage: file(relativePath: {eq: "stock/placeholder_3.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
}
`;

export default Members;
